/* styles go here */

#team {
  background-color: #330e85;
  color: #fff;
  padding-top: 9rem;
}

.team-content {
    padding: 40px;
    text-align: center;
}

.profile {
    width: 13rem;
}

.textPro {
    font-weight: bold;
}

.team-profiles {
    margin: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.contents-pro {
    display: flex;
    justify-content: center;
    justify-content: space-between;
}

.pro-link {
    color: #fff;
}

@media (max-width: 415px) and (min-width: 375px) {
    .profile {
        margin-bottom: 50px;
    }
}