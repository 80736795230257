/* Navbar */

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    padding: 15px;
    position: fixed;
    z-index: 8;
    background-color: #fff;
    height: 4rem;
}

.logo {
    display: flex;
    width: 7rem;
    margin-right: 15rem;
}

.nav-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    align-content: center;
}

#root > div > div:nth-child(1) > nav > ul {
    margin-bottom: 0px;
}

ul .page {
    text-decoration: none;
    font-weight: bold;
    padding: 0px 10px;
}

li a {
    color: #4011A6;
    text-decoration: none;
}

.logoImage {
    width: 12.669rem;
    margin-right: 19rem;
}

.Home,
.About,
.Charts,
.Roadmap,
.Whitepaper,
.Team {
    text-decoration: none;
    color: #4011A6;
    font-size: 20px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
}

.mobile-menu-icon {
    display: none;
}

@media screen and (max-width: 821px) {
    .navbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0;
        height: 4rem;
    }

    .logoImage {
        width: 6.25rem;
        margin-right: 0px;
    }

    .logo {
        display: flex;
        position: relative;
        left: 35px;
        width: 7rem;
        margin-right: 0;
    }

    .nav-links {
        display: none;
    }

    .socialMedia {
        display: none;
    }
    
    .nav-links-mobile {
        position: absolute;
        display: block;
        list-style-type: none;
        background-color: #fff;
        left: 0;
        top: 75px;
        transition: all 0.5s ease-out;
        width: 60%;
        height: 81vh;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style-type: none;
        align-items: center;
        padding: 45px 0px;
    }

    .Home,
    .About,
    .Charts,
    .Roadmap,
    .Whitepaper,
    .Team {
        color: #fff;
        text-align: center;
        font-size: 0.9rem;
        padding: 32px;
        width: 100%;
        transition: all 0.5s ease;
    }

    .first {
        margin-top: 50px;
    }

    .mobile-menu-icon {
        display: block;
        position: absolute;
        font-size: 30px;
        color: #4011A6;
        border: none;
        outline: none;
        top: 25px;
        right: 59px;
    }
}
