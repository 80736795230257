/* Footer Section */

#footer {
    background-color: #260A64;
    text-align: center;
    color: #fff;
}

.footer-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: auto;
    width: 20rem;
}

.termsCon {
    font-size: 18px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-decoration: underline;
    color: #fff;
    margin: 40px 0px;
}

.madeText {
    font-size: 18px;
}

.footer-sec {
    padding: 50px 0px;
}