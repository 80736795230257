/* styles go here */

#FAQ {
  background-color: #330e85;
  color: #fff;
}

.faq-contents {
  padding: 40px;
}

.texts1 {
  font-size: 56px;
  margin-bottom: 70px;
}

.ptFaq {
  padding-top: 9rem;
}

.flex-texts {
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: space-between;
  width: 83rem;
  font-size: 26px;
  font-family: "formula1-display-regular", sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  color: #fff;
  text-align: left;
}

.size {
  width: 85rem;
}

.click-btn {
  background-color: #330e85;
  border: none;
}

.line1 {
  width: 43rem;
}

.line2 {
  width: 25rem;
}

.line3 {
  width: 60rem;
}

.line4 {
  width: 12rem;
}

.line5 {
  width: 35rem;
}

/* Blog Section */

#Blog {
  background-color: #4011a6;
  color: #fff;
}

.text-class {
  font-size: 20px;
  text-align: center;
}

.blog-post {
  width: 25rem;
}

.blogImage {
  margin: 20px 0px;
}

.flex-blog {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

@media (max-width: 415px) and (min-width: 375px) {
  .texts1 {
    font-size: 35px;
    margin-bottom: 30px;
  }
  .flex-texts {
    width: 21rem;
    font-size: 15px;
  }
  .wi {
    width: 11rem;
  }
  .faq-contents {
    padding: 23px;
  }
  .line1 {
    width: 11rem;
  }
  .line2 {
    width: 8rem;
  }
  .line3 {
    width: 13rem;
  }
  .line5 {
    width: 9rem;
  }
  .size {
    width: 22rem;
  }
  .about-text {
    line-height: 20px;
    font-size: 13px;
  }
  .blog-post {
    width: 22rem;
    margin-bottom: 3rem;
  }
  .textalign {
    text-align: center;
  }
  .blogImage {
    margin: 20px auto;
    width: 16rem;
    display: flex;
    justify-content: center;
  }
  .ptFaq {
    padding-top: 9rem;
  }
}

@media (max-width: 913px) and (min-width: 415px) {
  .header-text {
    width: 37rem;
  }
  #Home {
    height: 70vh;
  }
  .background-shadow {
    height: 70vh;
  }
  .pick {
    width: 51rem;
  }
  .form-control {
    width: 30rem;
  }
  .control {
    width: 32rem;
  }
  .input-textarea {
    width: 30rem;
  }
  .patn {
    padding-top: 4.5rem;
  }
  .img-pieChart {
    width: 41rem;
  }
  .nav-links-mobile {
    height: 86vh;
  }
}
