/* styles go here */

/* Google Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body,
html {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
