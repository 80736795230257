/* styles go here */

/* Home Section */
#Home {
  background-image: url(../Static/026.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  color: #fff;
  z-index: 0;
}

.background-shadow {
  background: rgb(64, 17, 166);
  background: linear-gradient(
    90deg,
    rgba(64, 17, 166, 0.8) 0%,
    rgba(64, 17, 166, 0) 80%
  );
  position: absolute;
  top: 10;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
}

.home-section {
  padding: 4.5rem;
  padding-top: 9rem;
}

.pt {
  padding-top: 4.5rem;
}

h1 {
  -webkit-text-stroke: 1px #6641b8;
}

.header {
  font-size: 58px;
}

.header-text {
  margin-bottom: 60px;
  width: 70rem;
  position: relative;
  z-index: 6;
}

.cen {
  margin: auto 0px;
}

.header1 {
  font-size: 40px;
}

.subHeader {
  font-size: 30px;
  margin-bottom: 60px;
  position: relative;
  z-index: 6;
  display: flex;
  justify-content: space-between;
  width: 65rem;
}

.btns {
  position: relative;
  z-index: 6;
}

.btns,
.btnSale {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40rem;
}

.pinkSale {
  padding-left: 5px;
  width: 2rem;
}

.btnSale {
  margin: auto;
}

.myButton {
  background-color: #ffffff;
  border-radius: 50px;
  border: 1px solid #6641b8;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #6641b8;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 20px;
  text-decoration: none;
}

.Button,
.btnSubmit {
  background-color: #ffffff;
  border-radius: 50px;
  border: 1px solid #6641b8;
  display: inline-block;
  cursor: pointer;
  color: #6641b8;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding: 12px 20px;
  text-decoration: none;
}

.btnSubmit {
  box-shadow: 0px 6px 1px 0px #260a64;
  background-color: #ff5900;
  color: #fff;
  padding: 20px 40px;
}

.Button {
  box-shadow: 0px 6px 1px 0px #260a64;
}

.myButton:hover {
  background-color: #ff5900;
  color: #fff;
}

.Button:hover {
  background-color: #ff5900;
  color: #fff;
}

.btnSubmit:hover {
  background-color: #ffffff;
  color: #ff5900;
}

/* About Section */

#About,
#Experiences,
#Roadmap,
#Tokenomics,
#TokenSale,
#Contact {
  padding-top: 55px;
  background-color: #330e85;
  color: #fff;
  text-align: center;
}


.about-section {
  padding: 40px;
}

.heading-section {
  font-size: 56px;
  margin-bottom: 40px;
}

.about-text {
  line-height: 30px;
  font-size: 24px;
}

.img1 {
  margin: 50px 0px;
}

/* Experiences section */

#Experiences,
#Tokenomics,
#Contact {
  background-color: #4011a6;
}

.expe-section {
  font-size: 56px;
  padding: 50px 0px;
}

.expe-sec {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.pick {
  display: flex;
  flex-direction: column;
  width: 80rem;
  height: 40rem;
  margin: auto;
}

.boxes {
  width: 27rem;
  height: 20rem;
}

.buyH0U2 {
  background-image: url(../Static/Buy\ H0U2.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.watch {
  background-image: url(../Static/Watch.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.bet {
  background-image: url(../Static/Bet.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.ownTeam {
  background-image: url(../Static/OwnTeam.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.ownTrack {
  background-image: url(../Static/OwnTrack.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.earn {
  background-image: url(../Static/Earn.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.expe-text {
  font-size: 26px;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #ffffff;
  margin: 10rem 10px;
}

/* Roadmap Section */

.text-below {
  padding: 40px 40px;
}

/* Tokenomics Section */

.toke-text {
  font-size: 30px;
  font-weight: bold;
}

.img-pieChart {
  width: 50rem;
  margin: 40px 0px 40px 80px;
}

.test {
  display: flex;
  flex-direction: row;
}

.gitbookIcon {
  padding-right: 5px;
}

.btn-tok {
  padding-bottom: 30px;
}

/* Token Sale Section */

.flex-token {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.sale-token {
  text-align: left;
  width: 65rem;
}

.bold {
  font-weight: bold;
}

.tokenSpacing {
  margin: 50px 0px;
}

.tokenImg {
  width: 12rem;
}

/* Contact Section */

.padnn {
  padding-top: 0px;
}

.form-control {
  width: 40rem;
  height: 3.188rem;
  padding: 0rem 1rem;
  color: #212121;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.control {
  width: 42rem;
  height: 3.5rem;
  padding: 1rem;
  color: #212121;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.input-textarea {
  min-height: 120px;
  width: 40rem;
  padding: 1rem;
  resize: vertical;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-bottom: 20px;
}

@media (max-width: 415px) and (min-width: 375px) {
  #Home {
    height: 110vh;
    width: auto;
    background-image: url(../Static/backImage.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .header-text {
    text-align: center;
    width: 20rem;
  }
  .header {
    font-size: 29px;
  }
  .btns,
  .btnSale {
    width: 19rem;
  }
  .myButton {
    font-size: 11px;
    padding: 2px 6px;
  }
  .img-pieChart {
    width: 22rem;
    margin: 40px auto 40px 20px;
  }
  .sale-token {
    text-align: left;
    width: 18.5rem;
    margin: auto;
  }
  .subHeader {
    text-align: center;
    font-size: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
  }
  .header1 {
    font-size: 20px;
  }
  .heading-section,
  .expe-section {
    font-size: 20px;
  }
  .home-section {
    padding: 2.5rem;
    padding-top: 11.5rem;
  }
  .pt {
    padding-top: 2.5rem;
  }
  .about-text {
    text-align: center;
    line-height: 30px;
    font-size: 13px;
  }
  .flex-token {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .form-control {
    width: 18rem;
  }
  .control {
    width: 20.1rem;
  }
  .input-textarea {
    width: 18rem;
  }
  .buyH0U2,
  .watch,
  .bet,
  .ownTeam,
  .ownTrack,
  .earn {
    background-size: cover;
    width: 12rem;
    height: 7rem;
  }
  .expe-text {
    font-size: 10px;
    margin: 2.5rem 10px;
  }
  .homie {
    padding: 0.5rem;
  }
  .toke-text {
    font-size: 20px;
  }
  .pick {
    height: auto;
    width: auto;
  }
  .background-shadow {
    height: 110vh;
  }
  .text-below {
    font-size: 10px;
  }
  .ptnn {
    padding-top: 0px;
  }
  .vid {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
  .tokenImg {
    width: 9rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 375px) and (min-width: 289px) {
  #Home {
    height: 125vh;
    width: auto;
  }
  .background-shadow {
    height: 125vh;
  }
  .buyH0U2,
  .watch,
  .bet,
  .ownTeam,
  .ownTrack,
  .earn {
    background-size: cover;
    width: 11.5rem;
    height: 7rem;
  }
}
